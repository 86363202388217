<template>
  <div class="assetsEdit" v-if="hasResource('category_edit')">
    <div class>
      <div
        class="fz-18 cursor-pointer width-107 height-46 mg-b-20 text-back"
        @click="back"
      >
        <i class="iconfont fz-18 black">&#xe647;</i>
        <span class="back-text">返回</span>
      </div>
      <div class="editTitle">编辑分类</div>
      <!-- 分类名称 -->
      <div class="mg-t-56">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :inline="true"
          label-position="left"
          label-width="80px"
          v-loading="tableLoading"
        >
          <div>
            <el-form-item label="中文名称" prop="name_zh">
              <el-input
                v-model="form.name_zh"
                class="name-input"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="英文名称" prop="name">
              <el-input
                v-model="form.name"
                class="name-input"
                placeholder="请输入"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="序号"
              :required="true"
              prop="sort"
              class="inline-form"
              style="display: inline-block"
            >
              <el-input
                v-model="form.sort"
                class="assets-input"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item
              label="所属一级"
              prop="oneLevel"
              class="inline-form"
              style="display: inline-block; margin-right: 68px"
              v-if="
                this.$route.query.level == 3 || this.$route.query.level == 4
              "
            >
              <el-select
                v-model="form.oneLevel"
                placeholder="请选择"
                class="adddDalog-input"
                @change="handleFirst"
              >
                <el-option
                  v-for="main in options.mainMenu"
                  :key="main.id"
                  :label="main.name"
                  :value="main.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="所属二级"
              prop="twoLevel"
              style="display: inline-block"
              v-if="this.$route.query.level == 4"
            >
              <el-select
                v-model="form.twoLevel"
                placeholder="请选择"
                class="adddDalog-input"
                @change="handleSecond"
                :disabled="
                  options.subMenu && options.subMenu.length > 0 ? false : true
                "
              >
                <el-option
                  v-for="item in options.subMenu"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类列表配图" label-width="100px" width="85%">
              <img
                v-if="assetInfo.small_image"
                :src="assetInfo.small_image"
                alt
                class="assetsPhoto"
              />
              <div v-if="!assetInfo.small_image">
                <el-upload
                  style="display: inline"
                  name="uploadFileSmall"
                  :data="fileDataSmall"
                  accept=".jpg, .png"
                  action="#"
                  :auto-upload="false"
                  :on-change="handleSmall"
                  :show-file-list="false"
                >
                  <el-button class="up-button">上传</el-button>
                </el-upload>
                <div class="assets-photo">
                  <span>(334*344px)</span>
                </div>
              </div>
              <div class="mg-t-20" v-if="assetInfo.small_image">
                <div style="display: inline">
                  <el-button class="up-button" @click="handleDownloadPhoto">
                    <a
                      :href="assetInfo.small_image"
                      download
                      class="down-button"
                      >下载</a
                    >
                  </el-button>
                </div>
                <div class="assets-photo">
                  <el-upload
                    name="uploadFileSmall"
                    :data="fileDataSmall"
                    accept=".jpg, .png"
                    action="#"
                    :auto-upload="false"
                    :on-change="handleSmall"
                    :show-file-list="false"
                  >
                    <el-button class="up-button">重新上传</el-button>
                  </el-upload>
                </div>
                <div class="assets-photo">
                  <span>(334*344px)</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="分类详情配图" label-width="100px" width="85%">
              <img
                v-if="assetInfo.image"
                :src="assetInfo.image"
                alt
                class="assetsPhoto"
              />
              <div v-if="!assetInfo.image">
                <el-upload
                  style="display: inline"
                  name="uploadFile"
                  :data="fileData"
                  accept=".jpg, .png"
                  action="#"
                  :auto-upload="false"
                  :on-change="handlePreview"
                  :show-file-list="false"
                >
                  <el-button class="up-button">上传</el-button>
                </el-upload>
                <div class="assets-photo">
                  <span>(375*236px)</span>
                </div>
              </div>
              <div class="mg-t-20" v-if="assetInfo.image">
                <div style="display: inline-block">
                  <el-button class="up-button" @click="handleDownloadPhoto">
                    <a :href="assetInfo.image" download class="down-button"
                      >下载</a
                    >
                  </el-button>
                </div>
                <div class="assets-photo">
                  <el-upload
                    name="uploadFile"
                    :data="fileData"
                    accept=".jpg, .png"
                    action="#"
                    :auto-upload="false"
                    :on-change="handlePreview"
                    :show-file-list="false"
                  >
                    <el-button class="up-button">重新上传</el-button>
                  </el-upload>
                </div>
                <div class="assets-photo">
                  <span>(375*236px)</span>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 拖拽数据 -->
      <div class="bind-assets">
        已绑定资产
        <span>{{ assetLenth }}</span>
      </div>
      <!-- <draggable> -->
      <div class="tabData">
        <el-table
          :row-class-name="tableRowClassName"
          header-cell-class-name="table-head"
          cell-class-name="table-cell"
          :data="assetInfo.assets"
          row-key="id"
          ref="dragTable"
          v-loading="tableLoading"
        >
          <el-table-column
            v-for="col in tableColumns"
            :key="col.prop"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
            draggable="true"
          >
            <template slot-scope="{ $index, row }">
              <div v-if="col.prop == 'index'">{{ $index + 1 }}</div>
              <div v-else-if="col.prop == 'sort'">
                <i class="iconfont">&#xe663;</i>
              </div>
              <div v-else>{{ row[col.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="action-container add-dialog mg-t-40 pop-dialog">
        <div class="float-right">
          <el-button
            @click="submit"
            class="confirm-button"
            v-loading="submitLoading"
            :loading="submitLoading"
            >确 认</el-button
          >
          <el-button @click="back" class="cancel-button">取 消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import api from "../../../api/asset";
export default {
  components: {},
  data() {
    return {
      tableColumns: [
        // { prop: "sort", label: "拖拽排序", width: 100, align: "center" },
        { prop: "index", label: "序号", width: 140, align: "center" },
        { prop: "origin_id", label: "内部ID", width: 260, align: "center" },
        { prop: "asset_uid", label: "外部ID", width: 280, align: "left" },
        { prop: "name", label: "资产名称", align: "left" },
      ],
      submitLoading: false,
      tableLoading: false,
      header: {
        url: "roleManagement",
      },
      form: {
        name: "",
        name_zh: "",
        file: "",
        sort: "",
        oneLevel: "",
        twoLevel: "",
        id: "",
      },
      level: "",
      options: {
        mainMenu: [],
        subMenu: [],
      },
      assetInfo: {},
      assetLenth: 0,
      assetSort: [],
      checkImgUrl: "",
      fileData: {},
      fileDataSmall: {},
      rules: {
        name: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
          { min: 1, max: 20, message: "名称不超过20个字符", trigger: "blur" },
        ],
        name_zh: [
          { required: true, message: "请输入中文名称", trigger: "blur" },
          { min: 1, max: 20, message: "名称不超过20个字符", trigger: "blur" },
        ],
        oneLevel: [
          { required: true, message: "请选择所属一级", trigger: "blur" },
        ],
        twoLevel: [
          { required: true, message: "请选择所属二级", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入序号", trigger: "blur" }],
      },
      drageState: {
        start: -1,
        end: -1,
        move: -1,
        dragging: false,
        direction: null,
      },
      uploadCategory: "",
      uploadCategoryList: "",
      changeOptions: false,
    };
  },
  watch: {
    "form.oneLevel": function(val, oldVal) {
      // this.options.subMenu = (this.options.mainMenu || []).filter((el) => {
      //   return el.id == val;
      // });
    },
  },
  created() {
    this.init();
    if (this.hasResource("category_asset_sort")) {
      this.tableColumns.unshift({
        prop: "sort",
        label: "拖拽排序",
        width: 100,
        align: "center",
      });
    }
  },
  mounted() {
    // 阻止默认行为
    document.body.ondrop = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };
    if (this.hasResource("category_asset_sort")) {
      this.rowDrop();
    }
  },
  methods: {
    init() {
      this.tableLoading = false;
      let data = {
        market: "US",
        id: this.$route.query.id,
      };
      api.getCategoryInfo(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.tableLoading = false;
          this.assetInfo = resp.data.data;
          this.form.name = this.assetInfo.name;
          this.form.name_zh = this.assetInfo.name_zh;
          this.form.sort = this.assetInfo.sort;
          this.form.id = this.assetInfo.id;
          if (this.assetInfo.assets.length > 0) {
            this.assetLenth = this.assetInfo.assets.length;
          }
        }
      });
      //  获得资产分类
      this.getassetInfo();
    },
    getassetInfo() {
      let data = "US";
      api.getCategoryTree(data).then((resp) => {
        this.options.mainMenu = resp.data.data.children;
        // 处理显示一级二级
        let arr = [];
        if (this.$route.query.path) {
          arr = this.$route.query.path.split("/") || [];
        }
        if (arr.length > 3) {
          this.options.mainMenu.forEach((item, index) => {
            if (item.id == this.$route.query.parent_id) {
              this.form.oneLevel = item.id;
            }
            item.children &&
              item.children.forEach((child, indexs) => {
                if (child.id == this.$route.query.parent_id) {
                  this.form.twoLevel = child.id;
                  this.form.oneLevel = item.id;
                  this.level = this.$route.query.parent_id;
                  this.options.subMenu = item.children;
                }
              });
          });
        } else {
          this.form.oneLevel = parseInt(this.$route.query.id);
          this.level = this.$route.query.id;
        }
      });
    },
    back() {
      this.$router.push("/assets/categories");
      this.msgBus.$emit("categoriesList");
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.uploadCategory) {
            formData.append("image", this.uploadCategory);
            if (this.uploadCategoryList.size > 1024 * 1024 * 5) {
              this.$$error("详情图片过大， 不能超过5M.");
              return;
            }
          }
          if (this.uploadCategoryList) {
            formData.append("small_image", this.uploadCategoryList);
            if (this.uploadCategoryList.size > 1024 * 1024 * 5) {
              this.$$error("列表图片过大， 不能超过5M.");
              return;
            }
          }
          if (this.$route.query.level == 4 && !this.options.subMenu) {
            this.$$warning("请创建所属二级");
            return;
          }
          if (this.form.twoLevel === "请选择" && this.$route.query.level == 4) {
            this.$$warning("请选择所属二级");
            return;
          }
          let parent_id =
            this.$route.query.level == 2 || this.$route.query.level == 3
              ? this.changeOptions
                ? this.form.oneLevel
                : this.$route.query.parent_id
              : this.level;
          let sort = parseInt(this.form.sort);
          var formData = new FormData();
          formData.append("market", "US");
          formData.append("id", this.assetInfo.id);
          formData.append("name", this.form.name);
          formData.append("name_zh", this.form.name_zh);
          formData.append("parent_id", parent_id);
          formData.append("sort", sort);
          this.submitLoading = true;
          api.updataAsset(formData).then((resp) => {
            if (resp.data.code == 10200) {
              this.$$success("成功");
              this.$router.push("/assets/categories");
            } else {
              this.$$error(res.data.message);
            }
            this.submitLoading = false;
          });
          if (this.assetSort && this.assetSort.length != 0) {
            this.motifySort();
          }
        } else {
          return;
        }
      });
    },
    motifySort() {
      let sorts = {};
      for (let i = 0; i < this.assetSort.length; i++) {
        let item = this.assetSort[i];
        sorts[item.id] = i;
      }
      let data = {
        market: "US",
        sort: sorts,
        id: this.assetInfo.id,
      };
      api.assetSort(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.$$success("修改排序成功");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return "stop-row";
      }
      return "";
    },
    tableRowId(row) {
      return row.assetNumber;
    },
    rowDrop() {
      // 表格中需要实现行拖动，选中tr父级元素

      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.assetInfo.assets.splice(oldIndex, 1)[0];
          _this.assetInfo.assets.splice(newIndex, 0, currRow);
          _this.assetSort = _this.assetInfo.assets;
        },
      });
    },
    getPicFun(arr) {
      var tmp = [];
      if (arr) {
        arr.forEach((a) => {
          tmp.push({
            name: a.fileName,
            url: a.imgLink,
            urlCover: a.imgCover,
            fileId: a.fileId,
          });
        });
      }
      return tmp;
    },
    handleDownloadPhoto() {},
    cleaderData() {
      this.assetSort = [];
    },
    async handlePreview(file) {
      let fileRaw = file.raw;
      this.uploadCategory = fileRaw;
      let path = window.URL.createObjectURL(file.raw);
      let img = new Image();
      img.src = path;
      await this.asyncImageOnload(img);
      // if (img.width != 375 || img.height != 236) {
      //   this.$$error("请上传尺寸为375px*236px的图片");
      //   return;
      // }
      if (
        img.width < 375 ||
        img.height < 236 ||
        img.height / 236 != img.width / 375
      ) {
        this.$$error("请上传尺寸为375px*236px或其倍数的图片");
        return;
      }
      this.assetInfo.image = path;
    },
    async handleSmall(fileSmall) {
      let fileRawSmall = fileSmall.raw;
      this.uploadCategoryList = fileRawSmall;
      let pathSmall = window.URL.createObjectURL(fileRawSmall);
      let img = new Image();
      img.src = pathSmall;
      await this.asyncImageOnload(img);
      if (img.width != 334 || img.height != 344) {
        this.$$error("请上传尺寸为334px*344px的图片");
        return;
      }
      this.assetInfo.small_image = pathSmall;
    },
    asyncImageOnload(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          resolve();
        };
      });
    },
    handleFirst(val) {
      this.changeOptions = true;
      this.options.mainMenu.forEach((item, index) => {
        if (val === item.id) {
          this.options.subMenu = item.children;
        }
      });
      if (!this.options.subMenu) {
        this.form.twoLevel = "";
        this.level = val;
        return;
      }
      this.form.twoLevel = "请选择";
    },
    handleSecond(val) {
      this.level = val;
    },
  },
};
</script>

<style lang="less" scoped>
@import "index.less";
</style>
